<template>
    <div class="m-2">
       <h2>Twinwords API</h2>
        <div class="tooltip">  
            <span class="flex m-2">
                <p class="ml-2" v-for="word in this.word" :key="word.id" @click="wordFilter(word)">
                    {{word.v}}
                </p>   
            </span>  
            <span v-if="this.result != null">                                             
                <span class="tooltiptext recon_tooptip pt-2 pr-5 pl-5 br-6" md-direction="top" >
                    <span>
                        <b class="text-black" style="font-size: 1rem;" >{{this.result.entry}} 
                            (<small class="pl-0" v-for="n in  this.result" :key="n.id">
                                <span v-if="n.noun">noun,</span>
                                <span v-if="n.pronoun">pronoun,</span>
                                <span v-if="n.adverb">adverb,</span>
                                <span v-if="n.verb">verb,</span>
                                <span v-if="n.adjective">adjective</span>
                            </small>
                            )
                        </b>
                        <span style="display:flex">
                            <b class="text-black">Definition-:</b>
                            <span style="display:block">
                                <span style="display:block">
                                    <span style="display:block" v-for="n in this.result" :key="n.id">
                                        <span style="text-align:justify" class="text-black font-normal pb-1">
                                            <p v-if="n.noun">{{n.noun}},</p>
                                            <p v-if="n.adverb">{{n.adverb}},</p>
                                            <p v-if="n.verb">{{n.verb}},</p>
                                            <p v-if="n.adjective">{{n.adjective}},</p>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        
                        <span style="display:flex">
                            <b class="text-black">synonyms-:</b>
                            <span style="display:block">
                                <span style="display:block">
                                    <span style="display:block">
                                   
                                        <span style="text-align:left" class="text-black font-normal pb-1" v-for="n in this.syn" :key="n.id">
                                            <p class="pb-1" >{{n.synonyms}}</p>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>

                        <span style="display:flex">
                            <b class="text-black">Example-:</b>
                            <span style="display:block">
                                <span style="display:block">
                                    <span style="display:block">
                                   
                                        <span style="text-align:left" class="text-black font-normal pb-1" v-for="(n,i) in this.example.example" :key="n.id">
                                            <p class="pb-1">{{i+1}}. {{n}}</p>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        
                        <p style="text-align:left;font-size: 1rem;font-weight:600" class="text-blue pb-0">Pronunciation</p>
                        <b class="lan text-blue flex align-center" style="line-height:0;margin-top:5px;font-size: 1rem;">UK <span class="flex align-center text-black pl-3">{{this.result.entry}}<span @click="UKVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;" ><Volume/></span></span></b>
                        <b class="lan text-blue flex align-center" style="line-height:0;margin-top:7px;font-size: 1rem;">US <span class="flex align-center text-black pl-3">{{this.result.entry}}<span @click="USVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;"><Volume/></span></span></b><br>
                    </span>
                </span>
            </span> 
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Volume from '../../../../assets/svg/volume.svg';
export default {
  components:{
      Volume,
  },
  data() {
    return {
       result:null,
       example: null,
       syn: null,
        voice: '',
        word:[
            {
                v:'Practice'
            },
            {
                v:'such'
            },
            {
                v:'sentence'
            },
             {
                v:'in'
            },
            {
                v:'your'
            },
            {
                v:'home'
            },
             {
                v:'or'
            },
            {
                v:'with'
            },
            {
                v:'your'
            }, {
                v:'friends'
            },
            {
                v:'then'
            },
            {
                v:'you'
            }, {
                v:'will'
            },
            {
                v:'learn'
            },
            {
                v:'English'
            }, 
            {
                v:'very'
            }, 
            {
                v:'quickly.'
            },
        ],
        synonyms: null,
        tooltipActive: true,
        info: null,
        words: ''
    }
  },
  mounted () {
  },
  methods:{
        wordFilter(val){
            console.log(val);
            var options = {
                method: 'GET',
                url: 'https://twinword-word-graph-dictionary.p.rapidapi.com/definition/',
                params: {entry: val.v},
                headers: {
                    'x-rapidapi-host': 'twinword-word-graph-dictionary.p.rapidapi.com',
                    'x-rapidapi-key': '1d813d6ef0mshac2eb68f6e6951ap1d71f7jsn82e958f7704e'
                }
            };

            axios.request(options).then((response) => {
                this.result = response.data;
                console.log(this.result)
            }).catch(function (error) {
                console.error(error);
            });
            

            var syn = {
                method: 'GET',
                url: 'https://twinword-word-graph-dictionary.p.rapidapi.com/reference/',
                params: {entry: val.v},
                headers: {
                    'x-rapidapi-host': 'twinword-word-graph-dictionary.p.rapidapi.com',
                    'x-rapidapi-key': '1d813d6ef0mshac2eb68f6e6951ap1d71f7jsn82e958f7704e'
                }
            };
            axios.request(syn).then((response) => {
                this.syn = response.data;
                console.log(this.syn)
            }).catch(function (error) {
                console.error(error);
            });


            var example = {
                method: 'GET',
                url: 'https://twinword-word-graph-dictionary.p.rapidapi.com/example/',
                params: {entry: val.v},
                headers: {
                    'x-rapidapi-host': 'twinword-word-graph-dictionary.p.rapidapi.com',
                    'x-rapidapi-key': '1d813d6ef0mshac2eb68f6e6951ap1d71f7jsn82e958f7704e'
                }
            };
            axios.request(example).then((response) => {
                this.example = response.data;
                console.log(this.example)
            }).catch(function (error) {
                console.error(error);
            });
        }, 
  },
  
 
};
</script>
<style>

</style>