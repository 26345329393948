<template>
    <div>
        <h2>Free API</h2>
        <div class="tooltip">  
            <span class="flex m-2">
                <p class="ml-2" v-for="word in this.word" :key="word.id" @click="wordFilter(word)">
                    {{word.v}}
                </p>   
            </span>  
            <span v-if="this.info != null">                                             
                <span class="tooltiptext recon_tooptip pt-2 pr-5 pl-5 br-6" md-direction="top" >
                    <span v-for="(results,index) in this.info" :key="results.id" v-bind:class="[index === 0 ? 'show' : 'hidden']">
                        <b class="text-black" style="font-size: 1rem;" >{{results.word}} 
                            (<small class="pl-0" v-for="n in results.meanings" :key="n.id">{{n.partOfSpeech}},</small>)
                        </b>
                        <span style="display:flex">
                            <b class="text-black">Definition-:</b>
                            <span style="display:block" v-for="n in results.meanings" :key="n.id">
                                <span style="display:block" v-for="def in n.definitions" :key="def.id">
                                     <span style="text-align:justify" class="text-black font-normal pb-1" v-if="def.example != null">
                                         <p>{{def.definition}},</p>
                                     </span>
                                </span>
                            </span>
                        </span>
                        <span style="display:flex;position: relative;">
                           <b class="text-black">Synonyms-: </b> 
                            <span style="display:block;position: relative;" class="text-black font-normal pb-0 font-600" v-for="(n,i) in results.meanings" :key="n.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                                <span style="display:block" v-for="def in n.definitions" :key="def.id">
                                    <span v-if="def.synonyms != null" style="text-align: left;width:180px;display:inline-grid;position: relative;">
                                        <b class="text-black font-normal pb-0 font-600" v-for="syn in def.synonyms" :key="syn.id">{{syn}},</b>
                                    </span>
                                     
                                </span>
                            </span>
                        </span>
                         
                        <span style="display:flex">
                            <b class="text-black">Example-:</b>
                            <span style="display:block" v-for="n in results.meanings" :key="n.id">
                                <span style="display:block" v-for="def in n.definitions" :key="def.id">
                                     <span style="text-align:justify" class="text-black font-normal pb-1" v-if="def.example != null">
                                         <p>{{def.example}},</p>
                                     </span>
                                </span>
                            </span>
                        </span>

                         
                        
                        <p style="text-align:left;font-size: 1rem;font-weight:600" class="text-blue pb-0">Pronunciation</p>
                        <b class="lan text-blue flex align-center" style="line-height:0;margin-top:5px;font-size: 1rem;">UK <span class="flex align-center text-black pl-3">{{results.word}}<span @click="UKVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;" ><Volume/></span></span></b>
                        <b class="lan text-blue flex align-center" style="line-height:0;margin-top:7px;font-size: 1rem;">US <span class="flex align-center text-black pl-3">{{results.word}}<span @click="USVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;"><Volume/></span></span></b><br>
                    </span>
                </span>
            </span> 
        </div>
         <Twinwords/>
         <DictionaryApi/>
    </div>
</template>

<script>
import axios from 'axios';
import Volume from '../../../../assets/svg/volume.svg';
import Twinwords from './twinwords.vue';
import DictionaryApi from './dictionaryApi.vue';
export default {
  components:{
      Volume,
      Twinwords,
      DictionaryApi
  },
  data() {
    return {
       info: null,
       Base_url: 'https://api.dictionaryapi.dev/api/v2/entries/en/',
       value: '',
        results:[],
        voice: '',
        word:[
            {
                v:'Practice'
            },
            {
                v:'such'
            },
            {
                v:'sentence'
            },
             {
                v:'in'
            },
            {
                v:'your'
            },
            {
                v:'home'
            },
             {
                v:'or'
            },
            {
                v:'with'
            },
            {
                v:'your'
            }, {
                v:'friends'
            },
            {
                v:'then'
            },
            {
                v:'you'
            }, {
                v:'will'
            },
            {
                v:'learn'
            },
            {
                v:'English'
            }, 
            {
                v:'very'
            }, 
            {
                v:'quickly.'
            },
        ],
        synonyms: null,
        tooltipActive: true,
    }
  },
  computed: { 
  
  },
  mounted () {
  },
  methods:{
        wordFilter(val){
            console.log(val)
          const value = val.v
          this.tooltipActive = !this.tooltipActive;
          this.$root.voice = val.v
            axios
            .get(this.Base_url+value
            ).then((response) => {
                this.info = response.data
                console.log(this.info)
            })
            .catch((error) => {
                console.log(error)
            })   
        },
        USVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.$root.voice;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-US";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        },
        UKVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.$root.voice;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-GB";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        }
       
  },
  
 
};
</script>
<style>
.hidden{
 display: none !important;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.lan svg{
    height: 22px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 260px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>