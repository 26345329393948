<template>
    <div class="m-2">
       <h2>Dictionary API</h2>
        <div class="tooltip">  
            <span class="flex m-2">
                <p class="ml-2" v-for="word in this.word" :key="word.id" @click="wordFilter(word)">
                    {{word.v}}
                </p>   
            </span>  
            <span v-if="this.result != null">                                             
                <span class="tooltiptext recon_tooptip pt-2 pr-5 pl-5 br-6" md-direction="top" >
                    <span>
                        <b class="text-black" style="font-size: 1rem;" >{{words}} 
                            (<small class="pl-0" v-for="(n,i) in  this.result" :key="n.id" v-bind:class="[i < 5 ? 'show' : 'hidden']">{{n.fl}},</small>)
                        </b>
                        <span style="display:flex">
                            <b class="text-black">Definition-:</b>
                            <span style="display:block">
                                <span style="display:block" v-for="n in this.result" :key="n.id">
                                    <span style="display:block" v-for="def in n.shortdef" :key="def.id">
                                        <span style="text-align:justify" class="text-black font-normal pb-1">
                                            <p>{{def}},</p>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span style="display:flex;position: relative;">
                           <b class="text-black">stems-: </b> 
                            <span style="display:block;position: relative;" class="text-black font-normal pb-0 font-600" >
                                <span style="display:block" v-for="n in this.result" :key="n.id">
                                    <span style="text-align: left;width:180px;display:inline-grid;position: relative;" v-for="def in n.meta.stems" :key="def.id">
                                        <b class="text-black font-normal pb-0 font-600">{{def}},</b>
                                    </span>
                                     
                                </span>
                            </span>
                        </span>
                         
                        <span style="display:flex">
                            <b class="text-black">UROS-:</b>
                            <span style="display:block">
                                <span style="display:block" v-for="def in this.result" :key="def.id">
                                     <span style="text-align:justify" class="text-black font-normal pb-1" v-for="de in def.uros" :key="de.id">
                                         <p>{{de.ure}}: {{de.fl}}</p>
                                     </span>
                                </span>
                            </span>
                        </span>
                         
                        
                        <p style="text-align:left;font-size: 1rem;font-weight:600" class="text-blue pb-0">Pronunciation</p>
                        <b class="lan text-blue flex align-center" style="line-height:0;margin-top:5px;font-size: 1rem;">UK <span class="flex align-center text-black pl-3">{{words}}<span @click="UKVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;" ><Volume/></span></span></b>
                        <b class="lan text-blue flex align-center" style="line-height:0;margin-top:7px;font-size: 1rem;">US <span class="flex align-center text-black pl-3">{{words}}<span @click="USVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;"><Volume/></span></span></b><br>
                    </span>
                </span>
            </span> 
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Volume from '../../../../assets/svg/volume.svg';
export default {
  components:{
      Volume,
  },
  data() {
    return {
        result:null,
        voice: '',
        word:[
            {
                v:'Practice'
            },
            {
                v:'such'
            },
            {
                v:'sentence'
            },
             {
                v:'in'
            },
            {
                v:'your'
            },
            {
                v:'home'
            },
             {
                v:'or'
            },
            {
                v:'with'
            },
            {
                v:'your'
            }, {
                v:'friends'
            },
            {
                v:'then'
            },
            {
                v:'you'
            }, {
                v:'will'
            },
            {
                v:'learn'
            },
            {
                v:'English'
            }, 
            {
                v:'very'
            }, 
            {
                v:'quickly.'
            },
        ],
        synonyms: null,
        tooltipActive: true,
        info: null,
        words: ''
    }
  },
  mounted () {
  },
  methods:{
        wordFilter(val){
            this.words = val.v
            var options = {
                method: 'GET',
                url: 'https://www.dictionaryapi.com/api/v3/references/collegiate/json/'+val.v,
                params: {key: '202930f7-430d-450e-b23d-efbcc2389980'},

            };
            axios.request(options).then((response) => {
                const value = response.data;
                this.result = value;
                console.log(response)
            })
            // .catch(function (error) {
            //     console.error(error);
            // });
        }, 
        USVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.words;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-US";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        },
        UKVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.words;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-GB";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        }
  },
  
 
};
</script>
<style>

</style>